.slots
{
    grid-template-columns: repeat(1,minmax(0,1fr)) !important;
}

.slots > * > label {
    text-align: start !important;
    padding-left: 12px !important;
}

.slots > * > label > input {
    margin-right: 12px !important;
    width: 15px;
    height: 15px;
}

.ws-pupil-form
{
    margin-bottom: 16px !important;
}

.locatie .row
{
    margin-top: 32px !important
}